const config = {
  ENVIRONMENT_NAME: 'staging',
  forwoodId: {
    URL: 'https://id.staging.riotinto.forwoodsafety.com',
    USER_TOKEN_URL: 'https://n2g694c3v3.execute-api.us-east-1.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://70f75cs6r5.execute-api.us-east-1.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'teams-container-1.10.7',
    HOSTNAME: 'https://teams.staging.riotinto.forwoodsafety.com',
    TEAMS_MICROFRONTEND_URL: 'https://teams-service.staging.riotinto.forwoodsafety.com',
    TARGETS_MICROFRONTEND_URL: 'https://targets.staging.riotinto.forwoodsafety.com',
    TEAMS_ACCESS_ROLE: 'TeamsAccess',
    TEAM_TARGETS_ACCESS_ROLE: 'TeamTargetsAccess',
  },
  configurationService: {
    URL: 'https://config.staging.riotinto.forwoodsafety.com',
    WEBSOCKET: 'wss://0ed2rb09ge.execute-api.us-east-1.amazonaws.com/staging'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;
